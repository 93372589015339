import React, { useState, useEffect } from 'react';
import axios from 'axios';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

// Genişletilmiş ülke listesi ve ISO kodları
const countryData = {
  'Albania': { name: 'Arnavutluk', code: 'AL' },
  'Andorra': { name: 'Andorra', code: 'AD' },
  'Austria': { name: 'Avusturya', code: 'AT' },
  'Belgium': { name: 'Belçika', code: 'BE' },
  'Bosnia and Herzegovina': { name: 'Bosna Hersek', code: 'BA' },
  'Bulgaria': { name: 'Bulgaristan', code: 'BG' },
  'Croatia': { name: 'Hırvatistan', code: 'HR' },
  'Cyprus': { name: 'Kıbrıs', code: 'CY' },
  'Czechia': { name: 'Çek Cumhuriyeti', code: 'CZ' },
  'Denmark': { name: 'Danimarka', code: 'DK' },
  'Estonia': { name: 'Estonya', code: 'EE' },
  'Finland': { name: 'Finlandiya', code: 'FI' },
  'France': { name: 'Fransa', code: 'FR' },
  'Germany': { name: 'Almanya', code: 'DE' },
  'Greece': { name: 'Yunanistan', code: 'GR' },
  'Hungary': { name: 'Macaristan', code: 'HU' },
  'Iceland': { name: 'İzlanda', code: 'IS' },
  'Ireland': { name: 'İrlanda', code: 'IE' },
  'Italy': { name: 'İtalya', code: 'IT' },
  'Kosovo': { name: 'Kosova', code: 'XK' },
  'Latvia': { name: 'Letonya', code: 'LV' },
  'Liechtenstein': { name: 'Lihtenştayn', code: 'LI' },
  'Lithuania': { name: 'Litvanya', code: 'LT' },
  'Luxembourg': { name: 'Lüksemburg', code: 'LU' },
  'Malta': { name: 'Malta', code: 'MT' },
  'Moldova': { name: 'Moldova', code: 'MD' },
  'Monaco': { name: 'Monako', code: 'MC' },
  'Montenegro': { name: 'Karadağ', code: 'ME' },
  'Netherlands': { name: 'Hollanda', code: 'NL' },
  'North Macedonia': { name: 'Kuzey Makedonya', code: 'MK' },
  'Norway': { name: 'Norveç', code: 'NO' },
  'Poland': { name: 'Polonya', code: 'PL' },
  'Portugal': { name: 'Portekiz', code: 'PT' },
  'Romania': { name: 'Romanya', code: 'RO' },
  'San Marino': { name: 'San Marino', code: 'SM' },
  'Serbia': { name: 'Sırbistan', code: 'RS' },
  'Slovakia': { name: 'Slovakya', code: 'SK' },
  'Slovenia': { name: 'Slovenya', code: 'SI' },
  'Spain': { name: 'İspanya', code: 'ES' },
  'Sweden': { name: 'İsveç', code: 'SE' },
  'Switzerland': { name: 'İsviçre', code: 'CH' },
  'United Kingdom': { name: 'Birleşik Krallık', code: 'GB' },
  'Ukraine': { name: 'Ukrayna', code: 'UA' },
  'Vatican City': { name: 'Vatikan', code: 'VA' }
};

// Ülke çevirilerini uyumlu hale getir
const countryTranslations = Object.fromEntries(
  Object.entries(countryData).map(([key, value]) => [key, value.name])
);

// Bayrak URL'si oluştur
const getFlagUrl = (countryCode) => {
  if (!countryCode) return '';
  // Kosova için özel durum
  if (countryCode === 'XK') {
    return 'https://flagcdn.com/w40/xk.png';
  }
  return `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;
};

// Schengen Ülkeleri
const schengenCountries = [
  'Austria', 'Belgium', 'Czechia', 'Denmark', 'Estonia', 
  'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Iceland', 'Italy', 
  'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 
  'Norway', 'Poland', 'Portugal', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 
  'Switzerland'
];

// AB Üyesi Ülkeler
const euCountries = [
  'Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czechia', 
  'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 
  'Hungary', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 
  'Netherlands', 'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 
  'Spain', 'Sweden'
];

// API'den gelen ülke isimlerini standartlaştır
const standardizeCountryName = (countryName) => {
  if (!countryName) return '';
  if (countryName === 'Czech Republic') return 'Czechia';
  if (countryName === 'Lithuania TRP and National Visa') return 'Lithuania';
  return countryName;
};

function App() {
  const [visaData, setVisaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('all');
  const [selectedType, setSelectedType] = useState('schengen'); // Varsayılan olarak Schengen
  const [lastUpdate, setLastUpdate] = useState(null);
  const [notificationEnabled, setNotificationEnabled] = useState(false);
  const [notificationCountries, setNotificationCountries] = useState(new Set());

  // Bildirim fonksiyonları
  const checkNotificationPermission = () => {
    if (!("Notification" in window)) {
      alert("Bu tarayıcı bildirim özelliğini desteklemiyor");
      return false;
    }
    return true;
  };

  const toggleNotification = (country) => {
    if (!checkNotificationPermission()) return;

    const isEnabled = notificationCountries.has(country);
    
    if (!isEnabled) {
      // Bildirim izni iste
      Notification.requestPermission()
        .then(function(permission) {
          if (permission === "granted") {
            // State güncelle
            const newCountries = new Set(notificationCountries);
            newCountries.add(country);
            setNotificationCountries(newCountries);
            
            // LocalStorage güncelle
            localStorage.setItem('notificationCountries', JSON.stringify([...newCountries]));
            
            // Başarılı bildirimi
            new Notification("Bildirim Aktif", {
              body: `${country} için bildirimler açıldı`,
              icon: "/favicon.ico"
            });
          }
        });
    } else {
      // Bildirimi kapat
      const newCountries = new Set(notificationCountries);
      newCountries.delete(country);
      setNotificationCountries(newCountries);
      localStorage.setItem('notificationCountries', JSON.stringify([...newCountries]));
    }
  };

  // Bildirim butonu bileşeni
  const NotificationButton = ({ country }) => {
    const [isEnabled, setIsEnabled] = useState(false);

    const handleClick = () => {
      if (!("Notification" in window)) {
        alert("Bu tarayıcı bildirim özelliğini desteklemiyor");
        return;
      }

      if (Notification.permission === "granted") {
        setIsEnabled(!isEnabled);
        if (!isEnabled) {
          new Notification("Bildirimler Açıldı", {
            body: `${country} için bildirimler aktif edildi`
          });
        }
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            setIsEnabled(true);
            new Notification("Bildirimler Açıldı", {
              body: `${country} için bildirimler aktif edildi`
            });
          }
        });
      }
    };

    return (
      <button
        type="button"
        onClick={handleClick}
        className={`mt-2 px-4 py-2 rounded-full text-sm font-semibold ${
          isEnabled 
            ? 'bg-red-500 hover:bg-red-600 text-white' 
            : 'bg-blue-500 hover:bg-blue-600 text-white'
        }`}
      >
        {isEnabled ? '⛔ Bildirimleri Kapat' : '🛎️ Bildirimleri Aç'}
      </button>
    );
  };

  // Yeni randevu kontrolü ve bildirim gönderme
  const checkNewAppointments = (newData) => {
    if (!visaData.length) return;

    newData.forEach(newVisa => {
      if (!notificationCountries.has(newVisa.mission_country)) return;

      const oldVisa = visaData.find(v => 
        v.mission_country === newVisa.mission_country && 
        v.center_name === newVisa.center_name
      );

      if (oldVisa && !oldVisa.appointment_date && newVisa.appointment_date) {
        new Notification(`${countryTranslations[newVisa.mission_country] || newVisa.mission_country} - Yeni Randevu!`, {
          body: `${newVisa.center_name} için ${formatDate(newVisa.appointment_date)} tarihinde randevu açıldı!`,
          icon: '/favicon.ico'
        });
      }
    });
  };

  // Schengen vize kontrolü
  const isSchengenVisa = (visa) => {
    const category = (visa.visa_category || '').toLowerCase();
    const subcategory = (visa.visa_subcategory || '').toLowerCase();
    const country = visa.mission_country;
    
    // Eğer Schengen ülkesiyse ve kısa dönem/turistik vize ise
    if (schengenCountries.includes(country)) {
      return category.includes('schengen') ||
             category.includes('short term') ||
             category.includes('kisa donem') ||
             category.includes('court sejour') ||
             category.includes('short stay') ||
             category.includes('type c') ||
             category.includes('visa') ||
             subcategory.includes('tourist') ||
             subcategory.includes('turizm');
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Production'da log gösterme
        if (process.env.NODE_ENV === 'development') {
          console.log('🔄 Vize bilgileri alınıyor...');
        }

        const response = await fetch('https://api.schengenvisaappointments.com/api/visa-list/?format=json');
        const data = await response.json();

        // Sadece development modunda detaylı log göster
        if (process.env.NODE_ENV === 'development') {
          console.log('📊 Ham API Yanıtı:', data);
        }

        const turkeyOnly = data.filter(item => 
          item.source_country?.toLowerCase() === 'turkiye' ||
          item.source_country?.toLowerCase() === 'turkey' ||
          item.center_name?.toLowerCase().includes('ankara') ||
          item.center_name?.toLowerCase().includes('istanbul') ||
          item.center_name?.toLowerCase().includes('izmir')
        );

        // Development modunda log göster
        if (process.env.NODE_ENV === 'development') {
          console.log('🇹🇷 Türkiye Verileri:', turkeyOnly);
          console.log('🌍 Benzersiz Ülkeler:', [...new Set(turkeyOnly.map(item => item.mission_country))]);
          console.log(`📍 Toplam ${turkeyOnly.length} ülke bulundu`);
        }

        checkNewAppointments(turkeyOnly);
        setVisaData(turkeyOnly);
        setLastUpdate(new Date());
        setLoading(false);
      } catch (err) {
        // Hata mesajını güvenli hale getir
        const safeErrorMessage = 'Veri yüklenirken bir hata oluştu';
        if (process.env.NODE_ENV === 'development') {
          console.error('❌ API Hatası:', err);
        }
        setError(safeErrorMessage);
        setLoading(false);
      }
    };

    // Konsol temizleme girişimlerini engelle
    if (process.env.NODE_ENV === 'production') {
      const noop = () => {};
      const protectConsole = () => {
        const console = window.console;
        if (!console) return;
        
        const protect = (method) => {
          const original = console[method];
          Object.defineProperty(console, method, {
            get: () => noop,
            set: () => noop,
            configurable: false
          });
        };

        ['log', 'debug', 'info', 'warn', 'error', 'clear'].forEach(protect);
      };
      protectConsole();
    }

    fetchData();
    const interval = setInterval(fetchData, 300000);
    return () => clearInterval(interval);
  }, [notificationCountries]);

  const formatDate = (dateString) => {
    if (!dateString) return 'Randevu Yok';
    return new Date(dateString).toLocaleDateString('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  // Vize tiplerini grupla
  const visaTypes = ['all', 'schengen', 'tourist', 'business', 'family', 'other'];
  const visaTypeNames = {
    'all': 'Tüm Vizeler',
    'schengen': 'Schengen Vizesi',
    'tourist': 'Turist Vizesi',
    'business': 'İş/Ticari Vize',
    'family': 'Aile Ziyareti',
    'other': 'Diğer'
  };

  const getVisaType = (visa) => {
    if (isSchengenVisa(visa)) return 'schengen';
    
    const category = (visa.visa_category || '').toLowerCase();
    const subcategory = (visa.visa_subcategory || '').toLowerCase();

    if (subcategory.includes('business') || subcategory.includes('ticari')) return 'business';
    if (subcategory.includes('family') || subcategory.includes('aile')) return 'family';
    if (subcategory.includes('tourist') || subcategory.includes('turizm')) return 'tourist';
    return 'other';
  };

  // Filtreleme ve sıralama
  const filteredData = visaData
    .filter(visa => {
      const standardizedCountry = standardizeCountryName(visa.mission_country);
      const countryMatch = selectedCountry === 'all' || standardizedCountry === selectedCountry;
      const typeMatch = selectedType === 'all' || getVisaType(visa) === selectedType;
      return countryMatch && typeMatch;
    })
    .sort((a, b) => {
      // Önce randevusu olanları göster
      if (a.appointment_date && !b.appointment_date) return -1;
      if (!a.appointment_date && b.appointment_date) return 1;
      // Randevu tarihi varsa tarihe göre sırala
      if (a.appointment_date && b.appointment_date) {
        return new Date(a.appointment_date) - new Date(b.appointment_date);
      }
      // Son olarak ülke ismine göre sırala
      const aCountry = standardizeCountryName(a.mission_country);
      const bCountry = standardizeCountryName(b.mission_country);
      return (countryTranslations[aCountry] || aCountry)
        .localeCompare(countryTranslations[bCountry] || bCountry, 'tr');
    });

  // Ülkeleri alfabetik sırala
  const countries = [...new Set([
    ...Object.keys(countryTranslations),
    ...visaData.map(visa => standardizeCountryName(visa.mission_country))
  ])].sort((a, b) => (countryTranslations[a] || a).localeCompare(countryTranslations[b] || b, 'tr'));

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4">
        {error}
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-pink-900 py-8 animate-gradient-xy">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h1 className="text-5xl font-bold mb-2 text-white hover:text-blue-200 transition-colors duration-300 drop-shadow-lg">
            VizeRadar
          </h1>
          <p className="text-blue-200 text-lg">
            Schengen & Avrupa Vize Randevu Botu | Otomatik Vize Takip Sistemi
          </p>
          <p className="text-blue-200/80 text-sm mt-2">
            12+ Ülke için Anlık Vize Randevu Bildirimleri
          </p>
        </div>

        {lastUpdate && (
          <div className="text-center text-blue-200 text-sm mb-8 animate-pulse">
            Son Güncelleme: {lastUpdate.toLocaleTimeString('tr-TR')}
          </div>
        )}

        <div className="mb-8 max-w-4xl mx-auto backdrop-blur-lg bg-white/5 p-6 rounded-2xl shadow-2xl border border-white/10">
          <div className="text-center mb-4">
            <h2 className="text-white text-xl mb-2">Vize Randevu Kategorileri</h2>
            <p className="text-blue-200/80 text-sm">Schengen, Turist, İş/Ticari ve Aile Ziyareti Vizeleri</p>
          </div>
          <div className="flex flex-wrap justify-center gap-3 mb-6">
            {visaTypes.map(type => (
              <button
                key={type}
                onClick={() => setSelectedType(type)}
                className={`px-6 py-2.5 rounded-full transition-all duration-300 transform hover:scale-105 ${
                  selectedType === type
                    ? 'bg-white text-blue-900 shadow-lg hover:shadow-blue-300/50'
                    : 'bg-white/10 text-white hover:bg-white/20 shadow-md hover:shadow-lg'
                }`}
              >
                {visaTypeNames[type]}
              </button>
            ))}
          </div>

          <div className="relative">
            <select
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              className="w-full p-4 border border-white/10 rounded-xl bg-white/5 text-white shadow-lg hover:shadow-xl transition-all duration-300 appearance-none cursor-pointer backdrop-blur-lg hover:bg-white/10"
            >
              <option value="all">🌍 Tüm Ülkeler</option>
              {countries.map(country => (
                <option key={country} value={country} className="bg-gray-800 text-white">
                  {getFlagUrl(countryData[country]?.code) && '🏳️'} {countryTranslations[country] || country}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredData.length > 0 ? (
            filteredData.map((visa, index) => {
              const standardizedCountry = standardizeCountryName(visa.mission_country);
              return (
                <div 
                  key={index} 
                  className={`backdrop-blur-lg bg-white/5 border border-white/10 rounded-2xl p-6 shadow-2xl transition-all duration-300 transform hover:-translate-y-1 h-[32rem] flex flex-col
                    ${visa.appointment_date ? 'hover:bg-white/10' : 'hover:bg-white/8'}`}
                >
                  <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
                    <img 
                      src={getFlagUrl(countryData[standardizedCountry]?.code)} 
                      alt={`${standardizedCountry} bayrağı`}
                      className="w-8 h-6 object-cover rounded shadow-sm"
                    />
                    {countryTranslations[standardizedCountry] || standardizedCountry}
                  </h2>
                  <div className="space-y-3 text-blue-100 flex-grow overflow-auto">
                    <p className="flex items-center gap-2">
                      <span className="text-blue-200 min-w-[24px]">🏛️</span> 
                      <span className="break-words">{visa.center_name}</span>
                    </p>
                    <p className="flex items-center gap-2">
                      <span className="text-blue-200 min-w-[24px]">📆</span>
                      <span className={`font-medium ${visa.appointment_date ? 'text-green-400' : 'text-red-400'}`}>
                        {formatDate(visa.appointment_date)}
                      </span>
                    </p>
                    <p className="flex items-center gap-2">
                      <span className="text-blue-200 min-w-[24px]">🛂</span>
                      <span className="break-words">{visa.visa_category}</span>
                    </p>
                    {visa.visa_subcategory && (
                      <p className="ml-6 flex items-center gap-2">
                        <span className="text-blue-200 min-w-[24px]">📋</span>
                        <span className="break-words">{visa.visa_subcategory}</span>
                      </p>
                    )}
                    <p className="flex items-center gap-2">
                      <span className="text-blue-200 min-w-[24px]">👥</span>
                      <span className={`font-medium ${visa.people_looking > 10 ? 'text-red-400' : 'text-orange-400'}`}>
                        {visa.people_looking} kişi
                      </span>
                    </p>
                  </div>
                  {visa.book_now_link && (
                    <div className="flex flex-col gap-2 mt-6 pt-4 border-t border-white/10">
                      <a
                        href={visa.book_now_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block bg-blue-600 text-white px-4 py-3 rounded-xl text-center hover:bg-blue-700 transition-all duration-300 shadow-lg hover:shadow-blue-500/50 transform hover:-translate-y-0.5"
                      >
                        🔗 Randevu Al
                      </a>
                      <NotificationButton country={visa.mission_country} />
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="col-span-full text-center text-white py-12 backdrop-blur-lg bg-white/5 rounded-2xl shadow-2xl border border-white/10">
              <div className="text-6xl mb-4">🔍</div>
              Seçilen kriterlere uygun vize randevusu bulunamadı.
            </div>
          )}
        </div>

        <footer className="mt-12 text-center text-blue-200/60 text-sm">
          <p>VizeRadar - Schengen ve Avrupa Vize Randevu Botu</p>
          <p className="mt-1">Otomatik vize randevu takibi ve anlık bildirimler</p>
          <p className="mt-4 text-blue-200/40 text-xs">
            <a 
              href="https://jarvisdigitalagency.com" 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:text-blue-200/60 transition-colors"
            >
              Jarvis Agency
            </a>
            {' & '}
            <a 
              href="https://x.com/yigitech" 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:text-blue-200/60 transition-colors"
            >
              Yigitech
            </a>
            {' tarafından ❤️ ile oluşturuldu'}
          </p>
        </footer>
      </div>
    </div>
  );
}

export default App;